@import '../../styles/variables';
@import '../../styles/mixin';

.order-details__status {
  position: relative;
  user-select: none;
  ol {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @include media(sm) {
      flex-direction: row;
      justify-content: space-around;
      max-width: max-content;
    }
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1.5rem;
    text-transform: lowercase;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.12rem solid $primary;
      color: $primary;
      font-size: $text-xl;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;

      &.active {
        background: $primary;
        color: $white;
      }
    }

    &.active {
      color: $primary;
    }

    @include media(sm) {
      align-items: center;
      flex-direction: column;
      row-gap: 1.25rem;
    }
  }

  span {
    padding: 0rem 0.12rem;
    justify-content: center;
    align-items: center;
  }

  .dotted {
    height: 4.68rem;
    width: 1.5rem;
    background: $background-theme;
    background-size: 1.5rem 1.5rem;
    text-align: left;
    margin: 2.5rem 0rem 2.5rem 0.62rem;

    @include media(sm) {
      height: 1.5rem;
      width: 4.68rem;
      margin: 0.94rem 0rem 0rem 0rem;
    }
  }
}
