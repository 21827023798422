@import '../../../styles/variables';
@import '../../../styles/mixin';

.cards {
  display: flex;
  gap: 8px;

  @include media(lg) {
    order: 1;
  }
}

.card_container {
  position: relative;
  width: 35px;
  height: 24px;
}
