@import 'variables';
@import 'mixin';


.action__body_mobile {
  max-width: unset;
  min-width: 68px;
  top: 12px;
  left: 16px;

  @media screen and (max-width: 640px) {
      position: absolute;
      top: 12px;
      left: 8px;
      max-width: unset;
      min-width: 32px;
      flex-direction: column !important;
      z-index: 1;

      > div {
        display: none;
      }
  }
}

.actions__counter_mobile {
    @media screen and (max-width: 640px) {
       margin: 0 auto;
    }
}

