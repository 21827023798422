@import '../../styles/variables';
@import '../../styles/mixin';

.drawer__overlay {
  position: fixed;
  inset: 0;
  z-index: 19;
  background-color: rgba(0, 0, 0, 0.3);
}

.drawer__content {
   margin-top: 2rem;
   position: fixed;
   display: flex;
   flex-direction: column;
   border-top-left-radius: $border-radius-card;
   border-top-right-radius: $border-radius-card;
   background-color: $background-theme;
   box-shadow: $drawer-shadow;
   height: 100%;
   max-height: 96%;
   z-index: 20;
   bottom: 0;
   left:0;
   right: 0;
   outline: 0;
   cursor: grab;
   will-change: transform;
   -webkit-overflow-scrolling: touch;

   &_body {
     background-color: inherit;
     padding: 2.5rem;
     border-top-left-radius: $border-radius-card;
     border-top-right-radius: $border-radius-card;
   }

   &_icon {
    position: relative;
    margin: 0 auto;
    width: 3rem;
    height: 0.375rem;
    flex-shrink: 0;
    border-radius: 9999px;
    background-color: $drawer-icon-touch;
    display: block;
    top: -1.313rem;
  }

  &_thouch {
    position:relative;  
    height: 0;
  }

  &_more_info {   
    position: relative;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 40px;

    svg {
      *{
        fill: get-value($background-theme, $drawer-background);
      }
    }

    &_arrow {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      left: 50%;
      transform: translateX(-50%);
      background-color: get-value($gray-100, $drawer-background-more-info);
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
        font-weight: 700;
        color: get-value($text-color-default, $drawer-color-icon-more-info);
      }
    }

    .rotated {
      i {
        transform: rotate(180deg);
      }
    }
  }
};

.cart_nav__overlap {
  background: $overlay-bg;
  position: fixed;
  inset: 0;
  z-index: 19;
  transition: $transition-all;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  &_open {
    cursor:  pointer !important;
    visibility: visible !important;
    opacity: 1 !important;
    pointer-events: initial;
  }
}