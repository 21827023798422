@import '../../styles/variables';
@import '../../styles/mixin';

.order {
  .thankyou__fieldset {
    padding: 3rem 0rem;
    margin-bottom: 3rem;
    border-bottom: 0.06rem solid $gray-300;
  }

  .order-details__status {
    position: relative;

    ol {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      @include media(sm) {
        flex-direction: row;
        justify-content: space-around;
      }
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 1.5rem;
      text-transform: capitalize;

      figure {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $order-status-bg-color;
        border: 0.12rem solid $order-status-border-color;
        color: $order-status-color;
        font-size: $text-xl;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;

        &.active {
          background: $order-status-bg-active-color;
          color: $order-status-active-color;
          border: 0.12rem solid $order-status-border-active-color;
        }
      }

      &.active {
        color: $order-status-bg-active-color;
      }

      @include media(sm) {
        align-items: center;
        flex-direction: column;
        row-gap: 1.25rem;
      }
    }

    span {
      padding: 0rem 0.12rem;
      justify-content: center;
      align-items: center;
    }

    .dotted {
      height: 4.68rem;
      width: 1.5rem;
      background: $background-theme;
      background-size: 1.5rem 1.5rem;
      text-align: left;
      margin: 2.5rem 0rem 2.5rem 0.62rem;

      @include media(sm) {
        height: 1.5rem;
        width: 4.68rem;
        margin: 0.94rem 0rem 0rem 0rem;
      }
    }
  }

  .order__list-title {
    font-weight: 700;
    line-height: 1.5rem;
    padding-bottom: 1rem;
  }

  .order__payment-title {
    display: block;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 3rem 0rem 1.5rem 0rem;

    @include media(md) {
      display: none;
    }
  }

  table {
    border-collapse: collapse;
    text-align: left;
    margin: 0;
    padding: 0;
    width: 100%;

    figure {
      width: 100px;
      height: 75px;
      overflow: hidden;
      position: relative;

      @include media(md){
        width: 80px;
        height: 60px;   
      }

      img {
        height: auto;
      }
    }
  }

  table tr {
    color: $gray-600;
  }

  table th,
  table td {
    padding: 1rem 0.5rem 1rem 1rem;
    font-size: $text-base;
    font-weight: 400;
  }

  td:first-child {
    padding: 0.5rem;
    padding-left: 0.1rem;
    position: relative;
    width: 8rem;
  }

  td:nth-child(5) {
    width: 15rem;
  }

  table th {
    font-size: $text-base;
    font-weight: 400;
    color: $gray-500;
    border-bottom: 0.03rem solid $gray-200;
  }

  @media screen and (max-width: 768px) {
    table thead {
      display: none;
    }

    table tr {
      border-bottom: 0.06rem solid $gray-200;
      margin-bottom: 0.62em;
    }

    td:first-child,
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      width: 100%;
      text-align: right;
      display: flex;
      justify-content: space-between;
      font-size: $text-base;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
    }

    table td::before {
      /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
      content: attr(data-label);
      align-self: center;
      text-align: left;
      padding-right: 8px;
      font-weight: 400;
      color: $gray-500;
    }

    td:first-child {
      vertical-align: middle;
    }

    table tr:last-child {
      border-bottom: 0;
    }
  }

  .order__item-price {
    display: inline;
    margin-left: 0.25rem;
  }

  del {
    margin-right: 0.25rem;
    color: $errors;
  }

  ins {
    font-weight: 600;
  }

  .order__summary {
    display: flex;
    position: relative;
    justify-content: right;
    padding-bottom: 2.87rem;
    border-bottom: 0.06rem solid $gray-300;
  }

  .order__summary-body {
    width: 100%;
    border-top: 0;

    @include media(md) {
      max-width: 15rem;
      padding-top: 0.5rem;
      border-top: 0.06rem solid $gray-600;
    }

    p:nth-child(5) {
      span {
        font-weight: 500;
      }
    }
  }

  .order__summary-item {
    display: flex;
    padding: 0.5rem 1rem 0rem 1rem;
    justify-content: space-between;
    width: 100%;

    strong {
      color: $gray-400;
      font-weight: 400;
    }

    @include media(md) {
      max-width: 15rem;
    }
  }

  .order__summary-item-discounts {
    @extend .order__summary-item;

    strong,
    span {
      color: $errors;
    }
  }

  .order__info-route-map {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 3rem 0 0;
    column-gap: 8%;

    @include media(md) {
      flex-direction: row;
    }

    .order__information {
      order: 2;
      flex-grow: 0;

      @include media(md) {
        max-width: 17.5rem;
      }

      @include media(lg) {
        order: 1;
        max-width: 31rem;
      }
    }

    .order__info-thankyou {
      padding-top: 2.75rem;
      padding-bottom: 3rem;
      border-bottom: 0.06rem solid $gray-300;

      h3 {
        font-weight: 700;
        padding-bottom: 0.75rem;
      }

      p:nth-child(3) {
        padding-bottom: 1.25rem;
      }

      + .order__info-thankyou {
        padding-top: 3rem;
        padding-bottom: 0;
        border: 0;
      }
    }

    .order__map {
      order: 1;
      height: 6.25rem;
      min-height: 31rem;
      border-radius: $border-radius;
      overflow: hidden;
      margin-bottom: 1.56rem;
      flex-grow: 2;

      @include media(md) {
        order: 2;
      }
    }
  }
}
