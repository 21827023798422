@import '../../styles/variables';
@import '../../styles/mixin';

.ratingreviews {
  position: relative;
  margin: 48px 0px;

  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr);
  grid-template-rows: 40px 200px;
  column-gap: 30px;
  align-items: center;

  .rating__heading {
    position: relative;
    color: $gray-600;
    font-size: $text-xl;
    font-weight: 700;
    line-height: 24px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    margin-top: 0px;

    //position: relative;
    //display: table-row;
  }

  .rating__puntuation {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14;
    line-height: 18px;
    font-weight: 400;
    color: $primary-200;
    margin-top: 33px;

    strong {
      font-weight: 700;
      font-size: 56px;
      line-height: 68px;
      color: $states-btn-rating-color;
      margin-bottom: 15.57px;
    }

    .rating__card {
      justify-content: space-between;
    }

    div[class~='rating'] {
      margin-bottom: 16.07px;
    }

    [class~='icon'] {
      font-size: $text-xl;
      margin-right: 10px;
      color: $gray-200;
      width: 17.11px;
      height: 16.36px;
    }

    i[class~='icon_star'] {
      color: $rating-review-color;
    }

    i[class~='icon_star-half-alt'] {
      color: $rating-review-color;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 15.64px;
    margin-top: 27.57px;

    .rating__li-review {
      display: flex;
      align-items: center;
      align-content: center;

      i[class~='icon'] {
        font-size: $text-2xl;
        margin-right: 9px;
        color: $gray-100;
        align-self: center;
      }

      .rating__meter-bar {
        width: 148px;
        height: 4px;
        border: 1px solid $gray-200 !important;
        border-radius: $border-radius-sm;
        margin: 0px 16px 0px 8px;
        align-items: center;
        display: flex;

        .rating__progress {
          background: $rating-review-bar-color;
          height: 4px;
          border-radius: $border-radius-sm;
        }
      }

      span {
        width: 40px;
        text-align: center;
      }

      .rating__number {
        width: 15px;
      }
    }
  }
}

.placeholder__product_rating_reviews {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 52px;

  @include media(lg) {
    flex-direction: row;
    align-items: center;
  }

  .placeholder__product_rating {
    display: flex;
    column-gap: 16px;
    margin-bottom: 50px;
  }

  .placeholder__start_small {
    width: 20px;
    height: 20px;
    border-radius: 30%;
    background-color: $placeholder-bg-300;
  }

  .placeholder__product_rating_container {
    max-width: 496px;
    min-height: 180px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    column-gap: 30px;

    .placeholder__rating_puntuation {
      width: 187px;
      align-items: center;
      text-align: center;
      align-content: center;

      .placeholder__puntuation {
        height: 48px;
        width: 48px;
        margin-bottom: 16px;
        background-color: $placeholder-bg-100;
      }

      .placeholder__product_rating {
        margin-bottom: 0;
        justify-content: center;
      }
    }

    .placeholder__product_description {
      width: 296px;
      padding: 0 10px;
      row-gap: 16px;
    }

    .placeholder__progress {
      width: 100%;
      height: 10px;
    }
  }
}

.placeholder__rating_reviews_content {
  position: relative;

  .placeholder__product_rating_review_title {
    height: 20px !important;
    width: 180px !important;
    margin-bottom: 10px;
    background-color: $placeholder-bg-100;
  }

  .placeholder__product_status_container {
    display: grid;
    gap: 16px;
    width: 100%;
    max-height: 100px;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    grid-template-rows: repeat(2, 43px);
    overflow: hidden;

    .placeholder__state:nth-of-type(3) {
      background-color: transparent;
    }

    @include media(2xl) {
      width: 65%;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }

    @media screen and (min-width: 2054px) {
      .placeholder__state:nth-of-type(3) {
        background-color: $gray-50;
      }

      .placeholder__state:nth-of-type(4) {
        background-color: transparent;
      }
    }

    .placeholder__state {
      width: auto;
      min-width: 100px;
      height: 38px;
      border-radius: 20px;

      @include media(2xl) {
        max-width: 160px;
      }
    }
  }
}
