@import '../../../styles/variables';
@import '../../../styles/mixin';

.breadcrumb {
  display: flex;
  font-weight: 400;
  font-size: $text-sm;
  line-height: 24px;
  color: $secondary-400;
  margin-top: $padding-section-mobile * 2;
  margin-bottom: $padding-section-mobile;

  @include media(lg) {
    font-size: $text-base;
    margin-top: $padding-section * 2;
    margin-bottom: $padding-section;
  }

  .breadcrumb__item {
    white-space: nowrap;

    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .breadcrumb__item + .breadcrumb__item {
    &::before {
      content: '/';
      padding-left: 5px;
      padding-right: 5px;
      color: $secondary-400;
    }
  }

  .breadcrumb__item_active {
    color: $gray-400;
    cursor: default;
  }
}
