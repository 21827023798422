@import '../../../styles/variables';
@import '../../../styles/mixin';

.close {
  $size: calc($btn-close-font-size + $btn-close-padding);
  font-size: $btn-close-font-size;
  width: $size !important;
  height: $size !important;
  background-color: $btn-close-background-hover;
  border: 1px solid $btn-close-border-color;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 0 !important;

  i {
    color: $btn-close-icon-color !important;
  }

  &:hover {
    border: 1px solid $btn-close-border-color-hover;
    background-color: $btn-close-background-hover;
    transition: $transition-all;

    i {
      color: $btn-close-icon-color-hover !important;
    }
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}
