@import '../../styles/variables';
@import '../../styles/mixin';

.modal {
  border-radius: $border-radius;

  div[class~='modal__content'] {
    background-color: $card-validator-background;
  }
  
  .store_validator {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .header_title {
        font-weight: 500;
        font-size: $text-xl;
        line-height: 1.5rem;
        color: $gray-600;
      }
    }

    .warning_message {
      margin: 0;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 12px;

      button {
        width: 100%;
        text-transform: uppercase;
      }
    }
  }
}

.outside_products {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-grow: 1;
  height: fit-content;
  max-height: 330px;

  @include media(md) {
    max-height: 350px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .heading_level-4 {
    font-size: $text-sm;
    font-weight: 600;
    margin-bottom: 3px;
    white-space: normal;
    max-width: 250px;
  }

  .outside_product__item {
    display: flex;
    width: 100%;
    column-gap: 0.75rem;
    padding: 1.5rem 0;
    border-bottom: 0.5px solid $cart-divider;

    &_bordered:not(:last-child) {
      border-bottom: 0.5px solid $cart-validator-divider;
    }
  }

  .outside_product__item_image {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 4.5rem;
    flex-shrink: 0;
    background-color: $cart-body-bacground-image;
    border-radius: $cart-body-border-radius-image;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.25rem;

    figure {
      position: relative;
      background-color: inherit;
      overflow: hidden;
      min-width: 3.5rem;
      width: 3.5rem;
      height: 3.5rem;
    }

    img {
      border-radius: $cart-body-border-radius-image;
      height: auto;
    }

    .product__in_stock {
      color: $gray-300;
      font-size: 0.625rem;

      @media screen and (max-width: 400px) {
        font-size: 0.5rem;
      }
    }
  }

  .cart__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .cart__detail-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }

  /*  .price {
    del {
      line-height: 24px;
      font-size: $text-sm;
    }

    ins {
      font-size: $text-xl;
      line-height: 24px;
    }
  } */

  .cart__item-description {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    .cart__item-brand {
      font-weight: 400;
      font-size: $text-sm;
      line-height: 0.94rem;
      display: flex;
      align-items: center;
      gap: 0.375rem;
      color: $gray-600;
      text-transform: uppercase;
    }

    a {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: 0.75rem;

      .price__container {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        right: 2px;

        p {
          text-transform: uppercase;
          text-align: right;
          flex-grow: 1;

          del {
            font-weight: 400;
            font-size: $text-xs;
            line-height: 1rem;
            text-decoration-line: line-through;
            color: $discount-price;
          }

          ins {
            font-size: $text-base;
            display: block;
            font-weight: 700;
            line-height: 18px;
            color: $gray-600;
          }

          span {
            line-height: 14px;
            font-size: 10px;
          }
        }
      }
    }

    h4 {
      font-weight: 500;
      font-size: $text-base;
      line-height: 24px;
      color: $black;
      flex-shrink: 1;
    }

    .product__feats {
      font-weight: 400;
      font-size: $text-sm;
      line-height: 18px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.75rem;
      color: $gray-400;

      p {
        font-size: 0.75rem;
      }

      .flower__type {
        height: 1.25rem;
        width: fit-content;
        min-width: 2.31rem;
        padding: 2px 6px;
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-badge;
        text-align: center;
        display: block;
        cursor: default;

        strong {
          text-transform: uppercase;
        }
      }

      $flower_types: (
        'default' $flower-type-default-bg-color $flower-type-default-color,
        'sativa' $flower-type-sativa-bg-color $flower-type-sativa-color,
        'indica' $flower-type-indica-bg-color $flower-type-indica-color,
        'hybrid' $flower-type-hybrid-bg-color $flower-type-hybrid-color,
        'vape' $flower-type-vape-bg-color $flower-type-vape-color,
        'extract' $flower-type-extract-bg-color $flower-type-extract-color,
        'cbd' $flower-type-cbd-bg-color $flower-type-cbd-color
      );

      @each $name, $bg, $color in $flower_types {
        .type__#{$name} {
          background-color: $bg !important;
          color: $color !important;
        }
      }
    }

    .cart__product-variants {
      margin-top: 4px;
    }

    strong {
      font-weight: 500;

      .icon {
        color: $success;
        font-size: 17px;
      }
    }
  }

  .cart__item-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .counter {
      font-size: $text-sm;
      line-height: 16px;
      font-weight: 500;
      display: flex;
      align-self: flex-start;
      margin-top: 3px;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
